// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-server-templates-how-to-ts": () => import("../src/server/templates/how-to.ts" /* webpackChunkName: "component---src-server-templates-how-to-ts" */),
  "component---src-server-templates-simulator-ts": () => import("../src/server/templates/simulator.ts" /* webpackChunkName: "component---src-server-templates-simulator-ts" */),
  "component---src-server-templates-top-ts": () => import("../src/server/templates/top.ts" /* webpackChunkName: "component---src-server-templates-top-ts" */),
  "component---src-server-templates-category-ts": () => import("../src/server/templates/category.ts" /* webpackChunkName: "component---src-server-templates-category-ts" */)
}

